<template>
  <v-container fluid>
    <backorders-table></backorders-table>
  </v-container>
</template>

<script>
import BackordersTable from "@/components/domains/BackordersTable";

export default {
  components: {
    BackordersTable
  },
};
</script>

<style></style>
